import { styled } from '@mui/material';
import theme from '../../styles';
import { useScroll } from '../../components/ScrollContext';

const EducationCollaboration = (props) => {
  const { collaborations } = useScroll();
  return (
    <MainContainer ref={collaborations}>
      <H1>Colaboraciones</H1>
      <UnorderedList>
        <Item>SINAIN</Item>
        <Item>NORTH TEXAS SEMICONDUCTOR INSTITUTE</Item>
        <Item>FEDERACIÓN DE TRABAJADORES DE QUERETARO</Item>
        <Item>SINDICATO DE TLAXCALA</Item>
        <Item>FEDERACIÓN DE TRABAJADORES DE MATAMOROS</Item>
        <Item>FEDERACION DE TRABAJADORES DE AGUASCALIENTES</Item>
        <Item>UNIVERSIDAD TECNOLOGICA DE TLAXCALA</Item>
        <Item>UNIVERSDAD TECNOLOGICA FIDEL VELAZQUEZ</Item>
        <Item>STIMSS - OBREGON</Item>
        <Item>CLUSTER AUTOMOTRIZ METROPOLITANO</Item>
        <Item>SJOIIM- MATAMOROS, TAMAULIPAS</Item>
        <Item>FENATTIMM- AGUASCALIENTES</Item>
        <Item>SIDEGAS</Item>
      </UnorderedList>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '50px 100px',
  gap: 20,
}));
const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));
const UnorderedList = styled('ul')(() => ({
  listStyleType: 'circle',
}));
const Item = styled('li')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: theme.color.textPrimary,
  textAlign: 'left',
}));

export default EducationCollaboration;
