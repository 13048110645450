import { styled } from '@mui/material';
import theme from '../../../../styles';
import MainNavbar from '../../../../components/MainNavbar';
import AltratecNavbar from '../../../../components/AltratecNavbar';
import AltratecHeader from '../../../../sections/AltratecHeader';
import ContactSection from '../../../../sections/ContactSection';
import CoursesData from '../../../../data/courses';
import TitleAndParagraphs from '../../../../sections/TitleAndParagraphs';

function AltratecCourses() {
  return (
    <>
      <MainNavbar />
      <AltratecHeader />
      <AltratecNavbar />
      <MainContainer>
        <H1>Cursos</H1>
        <Paragraph>Estos son los cursos que ofrecemos:</Paragraph>
        {CoursesData.map((course, index) => (
          <TitleAndParagraphs
            key={index}
            title={course.name}
            list={course.list}
            padding='0'
          />
        ))}
      </MainContainer>
      <ContactSection />
    </>
  );
}

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '50px 100px',
  gap: 20,
}));
const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));
const Paragraph = styled('p')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: theme.color.textPrimary,
  textAlign: 'left',
}));

export default AltratecCourses;
