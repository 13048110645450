import { styled } from '@mui/material';
import theme from '../../styles';

const AltratecCapabilities = (props) => {
  return (
    <MainContainer>
      <H1>Capacidades de Altratec</H1>
      <UnorderedList>
        <Item>
          Es el primer Centro Inter-Industrial en Latinoamérica y es el socio de
          más experiencia en materia VET (Vocational Education and Training)
          para las empresas.
        </Item>
        <Item>
          Es una de las únicas organizaciones autorizadas en México como Centro
          de Evaluación y Certificación por CONOCER y CAMEXA.
        </Item>
        <Item>
          Cuenta con Reconocimientos de Validez Oficial de Estudios (RVOEs)
          federales aprobados por la Secretaría de Educación Publica en los
          niveles de educación media superior y superior.
        </Item>
        <Item>
          Acreditación como empresa autorizada para la Formación Dual Alemana
          por CAMEXA.
        </Item>
      </UnorderedList>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '50px 100px',
  gap: 20,
}));
const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));
const UnorderedList = styled('ul')(() => ({
  listStyleType: 'circle',
}));
const Item = styled('li')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: theme.color.textPrimary,
  textAlign: 'left',
}));

export default AltratecCapabilities;
