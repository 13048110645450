import React from 'react';
import { styled } from '@mui/material';
// import theme from '../../../../styles'

// import Navbar from '../../../../../components/Navbar';
import ContactSection from '../../../../sections/ContactSection';
import MainNavbar from '../../../../components/MainNavbar';
import AltratecHeader from '../../../../sections/AltratecHeader';
import AltratecNavbar from '../../../../components/AltratecNavbar';

const AltratecContact = () => {
  return (
    <MainContainer>
      {/* <Navbar color={theme.color.primary}></Navbar> */}
      <MainNavbar />
      <AltratecHeader />
      <AltratecNavbar />
      <ContactSection></ContactSection>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({}));

export default AltratecContact;
