import React from 'react';
import { styled } from '@mui/material';

const RichText = ({ content }) => {
  console.log('content: ', content);

  const renderChildren = (children) => {
    return children.map((child, index) => {
      let style = { ...baseTextStyle };
      if (child.bold) style.fontWeight = 'bold';
      if (child.italic) style.fontStyle = 'italic';
      if (child.underline) style.textDecoration = 'underline';
      if (child.strikethrough) style.textDecoration = 'line-through';

      if (child.type === 'link') {
        return (
          <a key={index} href={child.url} style={linkStyle}>
            {child.children[0].text}
          </a>
        );
      }

      return (
        <span key={index} style={style}>
          {child.text}
        </span>
      );
    });
  };

  const renderContent = (item) => {
    switch (item.type) {
      case 'heading':
        return (
          <div key={item.children[0].text} style={headingLevels[item.level]}>
            {renderChildren(item.children)}
          </div>
        );
      case 'paragraph':
        return (
          <Paragraph key={item.children[0].text}>
            {renderChildren(item.children)}
          </Paragraph>
        );
      case 'list':
        return (
          <List key={item.children[0].text} format={item.format}>
            {item.children.map((child, index) => (
              <li key={index}>{renderChildren(child.children)}</li>
            ))}
          </List>
        );
      default:
        return <Paragraph key={item.children[0].text}>{renderChildren(item.children)}</Paragraph>;
    }
  };

  return (
    <MainContainer>
      {content.map((item, index) => (
        <div key={index}>
          {renderContent(item)}
        </div>
      ))}
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '50px 100px',
}));

const baseTextStyle = {
  lineHeight: '1.5',
  color: '#666',
};

const linkStyle = {
  color: '#1e90ff',
  textDecoration: 'none',
};

const headingLevels = {
  1: {
    fontSize: '30px',
    fontWeight: 'bold',
    margin: '20px 0',
    color: '#333',
  },
  2: {
    fontSize: '25px',
    fontWeight: 'bold',
    margin: '20px 0',
    color: '#333',
  },
};

const Paragraph = styled('p')(() => ({}));

const List = styled('ul')(({ format }) => ({
  listStyleType: format === 'unordered' ? 'disc' : 'decimal',
  margin: '10px 0',
  paddingLeft: '20px',
}));

export default RichText;
