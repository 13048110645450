import MainNavbar from '../../../../components/MainNavbar';
import AltratecNavbar from '../../../../components/AltratecNavbar';
import AltratecHeader from '../../../../sections/AltratecHeader';
import ContactSection from '../../../../sections/ContactSection';
import ServicesData from '../../../../data/services';
import TitleAndParagraphs from '../../../../sections/TitleAndParagraphs';

function AltratecCareers() {
  return (
    <>
      <MainNavbar />
      <AltratecHeader />
      <AltratecNavbar />
      {ServicesData.map((service, index) => (
        <TitleAndParagraphs
          key={index}
          title={service.name}
          paragraphs={service.paragraphs}
        />
      ))}
      <ContactSection />
    </>
  );
}

export default AltratecCareers;
