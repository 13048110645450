const ServicesData = [
  {
    name: 'Nuestra oferta: "Servicios personalizados a la medida de las necesidades de cada empresa".',
    order: 1,
    paragraphs: [
      'La oferta educativa dual alemana de Altratec se hace a la medida de las necesidades de cada uno de nuestros clientes afiliados. Servicios como el diseño de nuestros programas, la preparación del personal formativo, el plan de rotación, la formación inter-industrial,  la pre-selección de alumnos, el seguimiento en la metodología, y la evaluacion y certificación se fundamentaran de manera individual a cada empresa tomando siempre en cuenta las necesidades profesionales para garantizar los resultados que la educación dual alemana ofrece.',
      'Lo siguiente es una descripción de nuestros modulos de servicio hecho a la medida para crear el programa de educación dual alemana para tu empresa:',
    ],
  },
  {
    name: 'Diseño del programa: ',
    order: 2,
    paragraphs: [
      'Altratec garantiza una formación de calidad al diseñar un programa dual personalizado para tu empresa adaptado a tus necesidades y recursos especifícos.',
      'Este diseño a la medida integra el progreso en las capacidades del alumno-colaborador que se adquieren en las diferentes etapas del proceso de formación de educación dual. Esto permite al estudiante la facultad de desarrollar actividades operativas con un objetivo claro y con miramientos a los resultados esperados en la empresa. El beneficio a la organización será el fortalecimiento de los varios procesos de operación internos, la mejora de calidad del trabajo; promoviendo así un entorno laboral más responsable y efectivo.',
      'Nuestra oferta educativa en el nivel medio-superior y superior puede ser aplicable a la mayoría de las ramas tecnológicas y administrativas en los sectores privados y públicos.',
    ],
  },
  {
    name: 'Preparación del personal formador (MEISTER)',
    order: 3,
    paragraphs: [
      'Para iniciar el proceso de educación dual alemán, es esencial entrenar a los supervisores operativos que serán responsables de la formación teórica y técnica del alumno-colaborador. El personal de formación dual  colaborará cercanamente con Altratec para ser formador en las áreas  estratégicas y operativas importantes para la organización en mira.',
      'El personal de formación impartirá habilidades técnicas, conocimientos teóricos y competencias  profesionales, que integrarán en las actividades focales a los alumnos-colaboradores y los guiarán hacia una posible contratación formal dentro de la empresa.',
      'El hecho de que los docentes formadores hayan pasado por un proceso de entrenamiento similar a los colaboradores-alumnos, abre las puertas a generar un ambiente generacional de educación y de mentoría de formador hacia alumno. A su vez, los alumnos-colaboradores también podran volverse fomadores, educando a nuevas generaciones de internos.',
      'Este ambiente generacional de formadores y alumnos hace que se disminuyan los costos asociados con la búsqueda, contratación y entrenamiento de personal dentro de la empresa.',
    ],
  },
  {
    name: 'Plan de rotación',
    order: 4,
    paragraphs: [
      'En el puesto de aprendizaje del alumno-colaborador (AZUBI) dentro de la empresa, se tomará en cuenta el perfil de facultades y capacidades individuales que tiene el alumno para así posicionarlo en aquellas actividades en las que pueda ser de mayor productividad para la organización.',
      'Así los alumnos-colaboradores tendrán un mayor crecimiento en las áreas donde demuestren proficiencia y valor para la empresa, así como conocer en su totalidad las responsabilidades de su area para tomar decisiones efectivas.',
      'Por ejemplo, un operador de una maquinaria de manfuactura, encargado en primer plano de sólo operarla, podrá tener si demuestra proficiencia, un entrenamiento en el conocimiento de mantenimiento de dicha maquinaria. Esto eleva al colaborador a ampliar su conocimiento general en las áreas mas importantes de sus actividades para la empresa. Complementando conocimientos propios del área con nuevas habilidades en el puesto de aprendizaje',
      'Permite crear un programa que responda directamente a las demandas particulares de la empresa, asegurando que los aprendices desarrollen habilidades y competencias necesarias para el crecimiento y la efectividad de la organización.',
    ],
  },
  {
    name: 'Formación interindustrial',
    order: 5,
    paragraphs: [
      'Los centros inter-industriales son una parte integral de la educación dual alemana. Si la empresa colaboradora no cuenta con los recursos necesarios como maquinaria de entrenamiento especializada, Altratec oferta la posibilidad de usar sus propias instalaciones de entrenamiento como sus instructores certificados para suplir esta necesidad. Altratec toma en cuenta las necesidades específicas del cliente empresa en desarrollar un programa sistemático de desarrollo y aprendizaje para la fuerza de trabajo.',
      'El entrenamiento que se imparte dentro de los centros inter-industriales de Altratec se agrupa en las siguientes modalidades:',
      'Entrenamiento básico: Se basa en ser el entrenamiento general que provee al estudiante con fundamentos sólidos para el análisis y la ejecución de tareas y procesos técnicos relevantes de su área.',
      'Entrenamiento Modular: Se basa en la flexibilidad de entrenar a los alumnos-colaboradores en las áras de interés que la empresa dictamine. Tomando y agregando lo que sea de mayor importancia para la organización.',
      'Entrenamiento Innovativo: Se basa en entrenar al alumno-colaborador en las áreas novedosas y actualizadas para desarrollar en el alumno una visión pragmática hacia dónde va la industria y actualizarse en los procesos de su área.',
    ],
  },
  {
    name: 'Pre-selección de alumnos',
    order: 6,
    paragraphs: [
      'En el modelo dual se seleccionan los candidatos por la misma industria, decidiendo cuantos candidatos desea para el proceso de formación,  el personal se desarrolla con base al requerimiento futuro y no se capacita en mayor número a la demanda requerida.',
      'Altratec propone candidatos para el puesto de aprendizaje requerido por la empresa colaboradora, y no capacita más alumnos de lo requerido.',
      'El registro de la experiencia del alumno candidato implica un largo proceso de selección, desde entrevistas con psicólogos y pruebas de inteligencia, hasta el cumplimiento de requisitos legales como la edad y consentimiento de los padres, en caso de ser menor de edad cursando media superior con Altratec.',
    ],
  },
  {
    name: 'Seguimiento en la metodología',
    order: 7,
    paragraphs: [
      'En el proceso de formación dual alemana, es crucial la participación activa de todas las partes involucradas: el formador en la empresa, el alumno-colaborador y el formador institucional. Esto permite crear un ambiente colaborativo que facilita el cumplimiento de los objetivos de desarrollo de aprendizaje en el puesto de trabajo.',
      'El alumno-colaborador tendrá acceso a una interfaz que registrará el cumplimento de metas de aprendizaje, como la auto-evaluación del alumno en ellas. Esta evaluación será importante para definir las áreas en las cuáles el alumno debe entrenarse, cómo saber cuales domina.',
      'El seguimiento del desarrollo de la educación dual dentro de la empresa permite detectar problemas o deficiencias en su implementación basado en el progreso de aprendizaje de los alumno-colaboradores. Esto cumple la efectividad del programa dual alemán.',
      'La implementación de un programa bien diseñado puede incorporar las últimas tendencias y tecnologías del sector, manteniendo a la empresa a la vanguardia y mejorando su posición competitiva en el mercado.',
    ],
  },
  {
    name: 'Evaluación y certificación',
    order: 7,
    paragraphs: [
      'Altratec es una de las únicas organizaciones mexicanas autorizadas como Centro de Evaluación y Certificación en México por CONOCER y CAMEXA.',
      'Altratec apoya y auxilia a CAMEXA en los procesos de evaluación y certificación de las competencias de las personas, conforme a Estándares de Competencia vigentes en el Registro Nacional de Estándares de Competencia.',
      'Para la evaluación del alumno-colaborador se evaluara paralelamente un certificado de conocimientos por el CONOCER para acreditar la formación dual mexicana y un certificado por Alemania a traves de CAMEXA  para acreditar la formación dual alemana. ',
      'La evaluación del alumno-colaborador será paralela, y será determinada por estándares alemanes y mexicanos. El certificado de conocimientos avalado por el CONOCER en México, y el certificado de CAMEXA en Alemania. Estos certificados se conjugarán para acreditar la educación dual por ambos países, obteniendo así una acreditación bilateral.',
      'Las evaluaciones teóricas-prácticas serán realizadas por Altratec,y son determinadas por un comité técnico cualificado. El comité técnico revisará e informará al CONOCER el desempeño acádemico aprobado por los alumnos.  CONOCER extiende a su vez extiende el certificado correspondiente a la formación dual mexicana.',
      'En el caso de la educación dual alemana se expide una doble certificación, las evaluaciones teórico-prácticas son realizadas por Altratec, evaluadas por un comité técnico de CAMEXA o de Alemania y este a su vez, lo remite a las autoridades educativas alemanas para expedición del certificado.',
    ],
  },
];

export default ServicesData;
