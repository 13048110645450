import { styled } from '@mui/material';
import theme from '../../styles';

const AltratecHistory = (props) => {
  return (
    <MainContainer>
      <H1>Historia de Altratec</H1>
      <Paragraph>
        El proyecto inicial de Educación Dual Alemán se realiza con personal
        entrenado en un programa bajo el mecanismo “Experto Integrado” que se
        llevo a cabo con subsidio de instituciones de cooperación bilateral de
        Alemania en los años de 1993 a 1999. Desde entonces, Altratec se dedica
        a multiplicar el Modelo Dual Alemán en el interior de la República
        Mexicana y se respalda con instituciones y organismos alemanes en sus
        labores.
        <br />
        <br />
        A petición del consorcio Volkswagen se diseño este proyecto de formación
        Dual de profesionistas a nivel medio-superior inicialmente en la región
        PUEBLA.
        <br />
        <br />
        Este proyecto entra en su fase de operación en enero de 1993 y tiene
        como modelo operativo la formación Dual (modelo de formación tecnólogica
        de Alemania) que ha probado ser exitosa en proyectos pilotos.
        <br />
        <br />
        En septiembre del año 1994, se decidió primero extender las actividades
        a la planta armadora Mercedes Benz en Santiago Tianguistenco.
        <br />
        <br />
        Altratec tiene hoy el reconocimiento de la Cámara Alemana (autoridad por
        mandato del Gobierno Alemán en el Sistema Dual) de formar bajo las
        normativas alemanas. Se cuenta con el Reconocimiento de Validez Oficial
        de Estudios (RVOE) de parte de la SEP para nivel medio superior en
        ingenierías industriales (Mecatrónica y Sistemas Computacionales).
      </Paragraph>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '50px 100px',
  gap: 20,
}));
const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));
const Paragraph = styled('p')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: theme.color.textPrimary,
  textAlign: 'left',
}));

export default AltratecHistory;
