import { styled } from '@mui/material';
import theme from '../../styles';
import image8020 from '../../assets/80-20-image.png';
import imageKonstrulab from '../../assets/konstrulab.png';
import imageInterindustrial from '../../assets/interindustrial.png';

const WhyAltratec = (props) => {
  return (
    <MainContainer>
      <H1>
        ¿Por qué Altratec es la propuesta superior de valor para la empresa?
      </H1>
      <H2>
        Altratec es la Alianza para la Transferencia Tecnológica
        Alemania-México.
      </H2>
      <Paragraph>
        Altratec nace de un proyecto de la cooperación con Alemania en 1993 e
        introduce en México el modelo de formación dual alemán, mediante el cuál
        el alumno se forma en el entrenamiento continuo en un centro
        especializado inter-industrial y en la empresa colaboradora. Dicho
        modelo sustituye los contextos escolarizados y hace aplicable los
        elementos teóricos, además de inducir al principio de la acción total o
        completa (Prinzip der totalen Handlung) con el objetivo de lograr
        competencia de acción (Handlungskompetenz).
        <br />
        <br />
        La educación dual alemana es una combinación de un 20% de pura teoría y
        80% teórico-práctico. Es parte del objetivo de integrar al estudiante en
        tareas relevantes en el ámbito laboral, permitiendo que se desarrollen
        las habilidades y desafíos comunes en un escenario realista. 
        <br />
        <br />
        El programa educativo dual de Altratec armoniza el entrenamiento
        práctico y teórico de una manera sistemática, obteniendo una respuesta
        directa para desarrollar la fuerza de trabajo con habilidades que
        requiere un mercado dinámico.
      </Paragraph>
      <CardContainer>
        <Card>
          <H2>Sistema de educación dual</H2>
          <Paragraph>
            Sistema mediante el cual el alumno se forma a partir del
            entrenamiento continuo en la empresa, basado en el modelo de
            formación profesional en Alemania, sustituye los elementos teóricos
            y contextos escolarizados.
          </Paragraph>
          <Image1 src={image8020} alt='Gráfica 80-20' />
        </Card>
        <Card>
          <H2>Konstrulab</H2>
          <Paragraph>
            Una hora diaria el estudiante aprende con la plataforma Konstrulab
            en las empresas.
            <br />
            <br />
            Esta aplicación contiene información orientada a las diferentes
            carreras participantes.
          </Paragraph>
          <Image2 src={imageKonstrulab} alt='Estudiante y laptop' />
        </Card>
        <Card>
          <H2>Formación interindustrial</H2>
          <Paragraph>
            Es beneficiosa para las empresas, ya que ofrece acceso a
            instalaciones, maquinaria y instructores certificados que no se
            pueden encontrar dentro de las propias empresas.
            <br />
            <br />
            Se cubren elementos clave como teoría principal, teoría específica,
            práctica y comunicación.
          </Paragraph>
          <Image3 src={imageInterindustrial} alt='Estudiante y laptop' />
        </Card>
      </CardContainer>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '50px 100px',
  gap: 20,
}));
const CardContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '50px 0',
  gap: 50,
}));
const Card = styled('div')(() => ({
  width: '33.33%',
  display: 'flex',
  flexGrow: 1,
  alignSelf: 'stretch',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 20,
  padding: 20,
  borderRadius: '5px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
}));
const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));
const H2 = styled('h2')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));
const Paragraph = styled('p')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: theme.color.textPrimary,
  textAlign: 'left',
}));
const Image1 = styled('img')(() => ({
  width: '100%',
  paddingRight: 20,
}));
const Image2 = styled('img')(() => ({
  width: '100%',
  padding: '10px 70px 0',
}));
const Image3 = styled('img')(() => ({
  width: '100%',
  padding: '10px 100px 0',
}));

export default WhyAltratec;
