import React, { useState, useEffect } from 'react';
import logo from '../../assets/nicdet-logotype.svg';
import logoPrimaryColor from '../../assets/nicdet-logotype-primary-color.svg';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useScroll } from '../ScrollContext';
import { styled } from '@mui/material';
import theme from '../../styles';

const Navbar = (props) => {
    const { memberships } = useScroll();
    const [scrollPosition, setScrollPosition] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToSection = (sectionRef) => {
        window.scrollTo({
            top: sectionRef.current.offsetTop - 73,
            behavior: 'smooth',
        });
    };

    const handleNavClick = (url) => {
        if (location.pathname !== url) {
            navigate(url);
            window.scrollTo(0, 0);
        } else {
            window.scrollTo(0, 0);
        }
    };

    return (
        <NavbarContainer scrollPosition={scrollPosition}>
            <LogoContainer>
                <NavLink to='/home'>
                    <img src={props.color === theme.color.primary ? logoPrimaryColor : logo} className='h-12 grid-logo' alt="Logo" />
                </NavLink>
            </LogoContainer>
            <LinksContainer>
                <NavLinkStyled
                    to='/home'
                    onClick={(e) => {
                        e.preventDefault();
                        handleNavClick('/home');
                    }}
                    props={props}
                >
                    Inicio
                </NavLinkStyled>
                <NavLinkStyled
                    to='/education'
                    onClick={(e) => {
                        e.preventDefault();
                        handleNavClick('/education');
                    }}
                    props={props}
                >
                    Educación
                </NavLinkStyled>
                <NavButtonStyled to='/contact' className="nav-link" props={props}>
                    Contacto
                </NavButtonStyled>
            </LinksContainer>
        </NavbarContainer>
    );
};

// Styled component using Material-UI's styled function
const NavbarContainer = styled('div')(({ scrollPosition }) => ({
    position: 'fixed',
    top: 0,
    zIndex: 50,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 100px',
    transition: 'background-color 0.3s ease, backdrop-filter 0.3s ease',
    backgroundColor: scrollPosition === 0 ? 'transparent' : 'rgba(148, 163, 184, 0.4)',
    backdropFilter: scrollPosition === 0 ? 'none' : 'blur(10px)',
    boxShadow: scrollPosition === 0 ? 'none' : '0 4px 6px rgba(0, 0, 0, 0.1)',
    fontFamily: theme.fontFamily.secondary,
    color: theme.color.light,
}));

const LogoContainer = styled('div')({
    flexShrink: 0,
    flexBasis: '180px',
});

const LinksContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
});

const NavLinkStyled = styled(NavLink)(({ props }) => ({
    color: props.color || theme.color.light,
    textDecoration: 'none',
    padding: '10px 20px',
    fontSize: '20px',
    transition: 'transform 0.3s ease-out',
    '&:hover': {
        color: theme.color.accent,
        transform: 'translateY(-10px)',
    },
}));

const NavButtonStyled = styled(NavLink)({
    color: theme.color.light,
    backgroundColor: theme.color.primary,
    display: 'flex',
    textDecoration: 'none',
    padding: '10px 20px',
    fontSize: '20px',
    borderRadius: '10px',
    transition: 'transform 0.3s ease-out',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
        transform: 'translateY(-10px)',
    },
});

export default Navbar;
