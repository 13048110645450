import React from 'react';
import { PrivacyNoticeContent } from './privacyNoticeContent'
import FormattedContent from '../../components/FormattedContent';
import { styled } from '@mui/material';

const PrivacyNotice = () => {
  return (
    <MainContainer>
      <h2>{PrivacyNoticeContent.Title}</h2>
      <FormattedContent content={PrivacyNoticeContent.Content} />
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({}));

export default PrivacyNotice;
