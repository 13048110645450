import { styled } from '@mui/material';
import MainHeroBackgroundImage from '../../assets/main-hero-background-image.png';
import theme from '../../styles'
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton';

const HeroSection = ({ props }) => {
  const MainContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end', // Center content if needed
    alignItems: 'flex-start', // Center content if needed
    position: 'relative',
    width: '100%',
    height: '100svh', // Adjust height as needed
    backgroundImage: `url(${MainHeroBackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 100,
    gap: 50,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% opacity black
      zIndex: 1,
    },
    '& > *': {
      position: 'relative',
      zIndex: 2,
    },
  }));
  const H1 = styled('h1')(() => ({
    color: 'white',
    fontFamily: theme.fontFamily.primary,
    fontWeight: 'bold',
    fontSize: 54,
    textAlign: 'left',
  }));
  const Span = styled('span')(() => ({
    color: theme.color.babyBlue
  }));
  const ButtonContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start', // Center content if needed
    alignItems: 'flex-end', // Center content if needed
    gap: 100
  }));

  return (
    <MainContainer>
      <H1><Span>Dedicados</Span><br /> a la investigación y el desarrollo de ciencia y tecnología</H1>
      <ButtonContainer>
        <PrimaryButton>CONTACTO</PrimaryButton>
        <SecondaryButton>SABER MÁS</SecondaryButton>
      </ButtonContainer>
    </MainContainer>
  );
};

export default HeroSection;
