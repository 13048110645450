import Navbar from '../../components/Navbar/index.jsx';
import HeroSection from '../../sections/HeroSection';
import Nosotros from '../../sections/Nosotros';

function Home() {
  return (
    <>
      <Navbar />
      <HeroSection />
      <Nosotros></Nosotros>
    </>
  );
}

export default Home;
