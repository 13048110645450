import { styled } from '@mui/material';
import theme from '../../styles';
import { useNavigate } from 'react-router-dom';
import AltratecIsotype from '../../assets/altratec-isotype-white.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const AltratecButton = (props) => {
  const navigate = useNavigate();

  const Button = styled('button')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    borderRadius: 5,
    backgroundColor: theme.color.red,
    color: theme.color.light,
    padding: '10px 20px',
    width: 'auto',
    border: 'none',
    fontSize: '20px',
    fontFamily: theme.fontFamily.secondary,
    fontWeight: '500',
    transition: 'transform 0.3s ease-out',
    '&:hover': {
      transform: 'translateY(-10px)',
    },
  }));
  const Isotype = styled('img')(() => ({
    width: '50px'
  }))

  const handleNavigate = (destination) => {
    navigate(destination);
  };

  return (
    <Button
      type={props.type || null}
      onClick={props.onClick ? props.onClick :
        props.destination ? () => handleNavigate(props.destination) : null
      }
    >
      <Isotype src={AltratecIsotype} />
      {props.children}
      <FontAwesomeIcon
        icon={faArrowRight} />
    </Button>
  );
};

export default AltratecButton;
