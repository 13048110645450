import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import theme from '../../../../styles'
import MainNavbar from '../../../../components/MainNavbar';
import AltratecNavbar from '../../../../components/AltratecNavbar';
import AltratecHeader from '../../../../sections/AltratecHeader';
import ContactSection from '../../../../sections/ContactSection';
import { getInfoBachelorsDegree } from '../../../../api/bachelorsDegree';
import RichText from '../../../../components/RichText'

function AltratecBachelorsDegree() {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState([]);
  const [image, setImage] = useState(null);

  useEffect(() => {
    handleContent();
  }, []);

  const handleContent = async () => {
    try {
      const response = await getInfoBachelorsDegree();
      const contentData = response.data.attributes.Content;
      const imageData = response.data.attributes.Image.data?.attributes;
      setContent(contentData);
      setImage(imageData || null);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
    }
  };

  return (
    <>
      <MainNavbar />
      <AltratecHeader />
      <AltratecNavbar />
      <MainContainer>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <RichText content={content} />
            {image ? (
              <ImageContainer>
                <H1>{image.caption}</H1>
                <Image
                  src={`${process.env.REACT_APP_API_URL}${image.formats.large.url}`}
                  alt={image.alternativeText}
                />
              </ImageContainer>
            ) : (
              <ImagePlaceholder>No image available</ImagePlaceholder>
            )}
          </>
        )}
      </MainContainer>
      <ContactSection />
    </>
  );
}

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '50px 100px',
  gap: 20,
}));

const ImageContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 20,
}));

const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontWeight: 'bold',
  fontSize: '30px',
  color: theme.color.textPrimary,
}));

const Image = styled('img')(() => ({
  width: '100%',
  maxWidth: '1000px',
}));

const ImagePlaceholder = styled('div')(() => ({
  width: '100%',
  height: '400px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f0f0f0',
  color: '#888',
  fontSize: '1.2em',
}));

export default AltratecBachelorsDegree;
