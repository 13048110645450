export const TermsAndConditionsContent = {
  Title: 'Términos y Condiciones',
  Content: `
    Bienvenido a NICDET: Núcleo de Investigación y Desarrollo Tecnológico.

    Estos términos y condiciones describen las reglas y regulaciones para el uso del sitio web de NICDET, ubicado en [www.nicdet.com].

    Al acceder a este sitio web, asumimos que aceptas estos términos y condiciones. No continúes usando NICDET si no estás de acuerdo con todos los términos y condiciones establecidos en esta página.

    Uso del Sitio Web
    - Propósito del Sitio: NICDET es un núcleo de investigación y desarrollo tecnológico. El contenido del sitio web es solo para fines informativos y no constituye asesoramiento profesional de ningún tipo.
    - Modificaciones al Sitio Web: NICDET se reserva el derecho de modificar, suspender o descontinuar, temporal o permanentemente, el sitio web o cualquier servicio al que se conecta, con o sin previo aviso y sin responsabilidad para ti.
    - Cuenta de Usuario: Para acceder a ciertas características del sitio web, es posible que debas registrarte y crear una cuenta. Eres responsable de mantener la confidencialidad de tu cuenta y contraseña y de todas las actividades que ocurran bajo tu cuenta.
    - Conducta del Usuario: Te comprometes a no usar el sitio web para:
      - Publicar contenido que sea ilegal, ofensivo, difamatorio, o que infrinja los derechos de terceros.
      - Interferir o interrumpir el funcionamiento del sitio web o los servidores o redes conectados al sitio web.

    Propiedad Intelectual
    Todos los derechos de propiedad intelectual del contenido del sitio web y el sitio en sí mismo pertenecen a NICDET o sus licenciantes. No debes reproducir, duplicar, copiar o utilizar de cualquier otro modo el contenido del sitio web sin el permiso expreso de NICDET.

    Limitación de Responsabilidad
    NICDET no será responsable de ningún daño indirecto, incidental, especial, consecuente o punitivo, incluyendo pero no limitado a la pérdida de datos, uso, ingresos o cualquier otra pérdida intangible, resultante de (i) el uso o la incapacidad de usar el sitio web; (ii) el acceso no autorizado o la alteración de tus transmisiones o datos.

    Ley Aplicable y Jurisdicción
    Estos términos y condiciones se rigen por y se interpretan de acuerdo con las leyes de [Tu País], y te sometes irrevocablemente a la jurisdicción exclusiva de los tribunales en ese Estado o ubicación.
  `
};
