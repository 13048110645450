import React, { useState, useEffect, forwardRef } from 'react';
import emailjs from 'emailjs-com';
import TextField from '@mui/material/TextField';
import { useScroll } from '../../components/ScrollContext';
import Swal from 'sweetalert2';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { styled } from '@mui/material';
import theme from '../../styles';
import CustomButton from '../../components/CustomButton';
import ContactSectionImage from '../../assets/contact-section-image.png';
import PrimaryButton from '../../components/PrimaryButton';

const ContactSection = forwardRef((props, ref) => {
  const { educationContact } = useScroll();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: '',
  });
  const [recaptcha, setRecaptcha] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Ensure reCaptcha key is available
  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  useEffect(() => {
    if (!reCaptchaKey) {
      console.error("reCaptcha key is not defined");
    }
  }, [reCaptchaKey]);

  useEffect(() => {
    console.log("Component mounted");

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      console.log("Component unmounted");
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted");

    const serviceID = process.env.REACT_APP_EMAIL_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
    const userID = process.env.REACT_APP_EMAIL_USER_ID;

    if (!recaptcha) {
      console.log('Recaptcha failed');
      Swal.fire('Error', 'Please complete the reCaptcha verification.', 'error');
      return;
    }

    emailjs
      .sendForm(serviceID, templateID, e.target, userID)
      .then((response) => {
        console.log('Email sent successfully:', response);
        setFormData({
          name: '',
          email: '',
          company: '',
          message: '',
        });
        Swal.fire('¡Éxito!', 'Email enviado correctamente', 'success');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        Swal.fire('Error', 'No se pudo enviar el email. Por favor, inténtalo de nuevo más tarde.', 'error');
      });
  };

  if (!reCaptchaKey) {
    return null; // or render some fallback UI
  }

  return (
    <MainContainer onSubmit={handleSubmit} ref={educationContact}>
      <TextInputContainer>
        <H1>Conectemos ideas</H1>
        <H2>Contáctanos si te interesa formar parte de este sistema o si necesitas información adicional.</H2>
        <CustomTextField
          label='Nombre'
          variant='filled'
          type='text'
          id='name'
          name='name'
          value={formData.name}
          onChange={handleChange}
          required
        />
        <CustomTextField
          label='Correo'
          variant='filled'
          type='email'
          id='email'
          name='email'
          value={formData.email}
          onChange={handleChange}
          required
        />
        <CustomTextField
          label='Empresa'
          variant='filled'
          id='company'
          name='company'
          value={formData.company}
          onChange={handleChange}
          required
        />
        <CustomTextField
          label='Mensaje'
          variant='filled'
          id='message'
          name='message'
          value={formData.message}
          onChange={handleChange}
          required
          multiline
          maxRows={4}
        />
        <PrimaryButton
          type='submit'
          width='100%'
          padding='10px'
          backgroundColor={theme.color.accent}
          color={theme.color.textPrimary}
        >
          Enviar
        </PrimaryButton>
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
          <GoogleReCaptcha onVerify={() => setRecaptcha(true)} />
        </GoogleReCaptchaProvider>
      </TextInputContainer>
      <ImageContainer>
        <Image src={ContactSectionImage} alt='People in a meeting'></Image>
      </ImageContainer>
    </MainContainer>
  );
});

const MainContainer = styled('form')(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.color.lightGray,
  borderRadius: 20,
  margin: 100,
  padding: 20,
  gap: 50,
  '@media (max-width: 600px)': {
    padding: '30px 10px',
  },
}));

const H1 = styled('h1')(() => ({
  color: theme.color.textDark,
  fontSize: '30px',
  fontWeight: '700',
}));

const H2 = styled('h2')(() => ({
  color: theme.color.textDark,
  fontSize: '16px',
  fontWeight: '500',
}));

const ImageContainer = styled('div')(() => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Image = styled('img')(() => ({
  width: '100%',
  height: 'auto',
  maxHeight: '100%',
  objectFit: 'cover',
}));

const TextInputContainer = styled('div')({
  width: '50%',
  maxWidth: '600px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: 40,
  gap: 20,
  backgroundColor: theme.color.cream,
});

const CustomTextField = styled(TextField)({
  width: '100%',
  backgroundColor: 'white',
  borderRadius: '5px',
  '& .MuiFilledInput-root': {
    width: '100%',
    backgroundColor: theme.color.light,
    transition: 'box-shadow 0.3s ease-in-out',
  },
  '& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after': {
    borderBottom: `2px solid ${theme.color.primary}`,
  },
  '& .MuiFilledInput-root:hover': {
    borderBottom: `2px solid ${theme.color.primary}`,
    backgroundColor: theme.color.hoverLight,
  },
  '& .MuiFilledInput-underline:hover:before': {
    borderBottom: 'none !important',
  },
  '& .MuiInputLabel-root': {
    fontWeight: 'bold',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.color.textPrimary,
  },
  '& .MuiInputLabel-asterisk': {
    display: 'none',
  },
});

export default ContactSection;
