import MainNavbar from '../../../components/MainNavbar';
import AltratecNavbar from '../../../components/AltratecNavbar';
import AltratecHeader from '../../../sections/AltratecHeader';
import AltratecSlider from '../../../sections/AltratecSlider';
import WhyAltratec from '../../../sections/WhyAltratec';
import AltratecServices from '../../../sections/AltratecServices';
import AltratecOperations from '../../../sections/AltratecOperations';
import AltratecFormationProgram from '../../../sections/AltratecFormationProgram';
import AltratecHistory from '../../../sections/AltratecHistory';
import AltratecCapabilities from '../../../sections/AltratecCapabilities';
import AltratecCollaborations from '../../../sections/AltratecCollaborations';
import ContactSection from '../../../sections/ContactSection';

function EducationAltratec() {
  return (
    <>
      <MainNavbar />
      <AltratecHeader />
      <AltratecNavbar />
      <AltratecSlider />
      <WhyAltratec />
      <AltratecServices />
      <AltratecOperations />
      <AltratecFormationProgram />
      <AltratecHistory/>
      <AltratecCapabilities/>
      <AltratecCollaborations/>
      <ContactSection/>
    </>
  );
}

export default EducationAltratec;
