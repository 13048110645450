import { useEffect } from 'react';
import AuthProvider from './auth/authProvider';
import './App.css';
import Router from './routes/router.js';
import Footer from './sections/Footer/index.jsx';

import { ScrollProvider } from './components/ScrollContext.jsx';

function App() {
  useEffect(() => {
    console.log('printing');
  }, []);

  return (
    <AuthProvider>
      <ScrollProvider>
        <Router></Router>
        <Footer></Footer>
      </ScrollProvider>
    </AuthProvider>
  );
}

export default App;
