import { styled } from '@mui/material';
import theme from '../../styles';

const SecondaryButton = (props) => {
  const Button = styled('button')(() => ({
    width: 'auto',
    padding: '20px 90px',
    borderRadius: 10,
    color: theme.color.accent,
    backgroundColor: theme.color.light,
    fontSize: '18px',
    fontWeight: '700',
    fontFamily: theme.fontFamily.secondary,
    transition: 'transform 0.3s ease-out',
    textTransform: 'uppercase',
    '&:hover': {
      transform: 'translateY(-10px)',
    },
  }));

  return <Button>{props.children}</Button>;
};

export default SecondaryButton;
