import { styled } from '@mui/material';
import MainHeroBackgroundImage from '../../assets/education-header.png';
import theme from '../../styles'
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton';

const EducationHeader = ({ props }) => {
  const MainContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'relative',
    width: '100%',
    height: '70svh', // Adjust height as needed
    backgroundImage: `url(${MainHeroBackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '100px 100px 50px 100px',
    gap: 50,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% opacity black
      zIndex: 1,
    },
    '& > *': {
      position: 'relative',
      zIndex: 2,
    },
  }));
  const TopContainer = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  }));
  const BottomContainer = styled('div')(() => ({
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  }));
  const H1 = styled('h1')(() => ({
    color: 'white',
    fontFamily: theme.fontFamily.tertiary,
    fontWeight: 'bold',
    fontSize: 70,
    textAlign: 'right',
  }));
  const H2 = styled('h2')(() => ({
    color: 'white',
    fontFamily: theme.fontFamily.tertiary,
    fontWeight: '800',
    fontSize: 45,
    textAlign: 'left',
  }));
  const H3 = styled('h3')(() => ({
    color: 'white',
    fontFamily: theme.fontFamily.tertiary,
    fontWeight: '500',
    fontSize: 35,
    textAlign: 'left',
  }));

  return (
    <MainContainer>
      <TopContainer>
        <H1>Educación</H1>
      </TopContainer>
      <BottomContainer>
        <H3>Modelo de formación profesional Alemán</H3>
        <H2>¡Ahora en México!</H2>
      </BottomContainer>
    </MainContainer>
  );
};

export default EducationHeader;
