import React, { useEffect } from 'react';
import { TermsAndConditionsContent } from './termsAndConditionsContent';
import FormattedContent from '../../components/FormattedContent';
import { styled } from '@mui/material';

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = 'Términos y condiciones - NICDET';
  }, []);

  return (
    <MainContainer>
      <h2>{TermsAndConditionsContent.Title}</h2>
      <FormattedContent content={TermsAndConditionsContent.Content} />
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({}));

export default TermsAndConditions;
