const CoursesData = [
  {
    name: 'Manufactura y automatización industrial',
    order: 1,
    description: null,
    duration: null,
    list: [
      'Tecnologia de metales',
      'Soldadura',
      'CAD/CAM',
      'CNC',
      'Instalaciones industriales',
      'Control Logico Programable',
      'Neumatica',
      'Hidraulica',
      'Maquinado',
      'Moldes y Troqueles',
      'Automatización y Control',
      'Motores y Servomotores',
      'Diseño mecánico',
    ],
  },
  {
    name: 'Tecnologías de información',
    order: 2,
    description: 'También conocido como Cómputo y redes',
    duration: null,
    list: [
      'IOT y comunicación entre maquinas',
      'Comunicación digital',
      'Programación',
      'Bases de Datos',
      'Big Data',
      'Inteligencia Artificial y Almacenamiento de Información digital',
      'Realidad virtual y aumentada',
    ],
  },
  {
    name: 'Semiconductores',
    order: 3,
    description: null,
    duration: null,
    list: ['Fotolitografía'],
  },
  {
    name: 'Electromovilidad',
    order: 4,
    description: null,
    duration: null,
    list: [
      'Procedimientos de electromovilidad',
      'Manejo de sistemas de alto voltaje',
      'Instalación de estaciones de carga',
    ],
  },
];

export default CoursesData;
