import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useScroll } from '../ScrollContext';
import { styled } from '@mui/material';
import theme from '../../styles';

// Styled component using Material-UI's styled function
const NavbarContainer = styled('div')(({ scrollPosition }) => ({
    position: 'sticky',
    top: 0,
    zIndex: 50,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 100px',
    transition: 'background-color 0.3s ease, backdrop-filter 0.3s ease',
    backgroundColor: scrollPosition === 0 ? 'transparent' : 'rgba(255, 255, 255, 0.4)',
    backdropFilter: scrollPosition === 0 ? 'none' : 'blur(10px)',
    boxShadow: scrollPosition === 0 ? 'none' : '0 4px 6px rgba(0, 0, 0, 0.1)',
    fontFamily: theme.fontFamily.secondary,
    color: theme.color.textPrimary,
}));

const LinksContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
});

const NavLinkStyled = styled(NavLink)({
    color: theme.color.textPrimary,
    textDecoration: 'none',
    padding: '10px 20px',
    fontSize: '20px',
    transition: 'transform 0.3s ease-out',
    '&:hover': {
        color: theme.color.primary,
        transform: 'translateY(-10px)',
    },
});

const EducationNavbar = () => {
    const { educationContact, collaborations, events, memberships, specializedTraining } = useScroll();
    const [scrollPosition, setScrollPosition] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);


    useEffect(() => {
        if (location.hash === '#contact') {
            scrollToSection(educationContact);
        }
    }, [location, educationContact]);

    useEffect(() => {
        if (location.hash === '#collaborations') {
            scrollToSection(collaborations);
        }
    }, [location, collaborations]);
    
    useEffect(() => {
        if (location.hash === '#events') {
            scrollToSection(events);
        }
    }, [location, events]);

    useEffect(() => {
        if (location.hash === '#specialized-training') {
            scrollToSection(specializedTraining);
        }
    }, [location, specializedTraining]);


    const scrollToSection = (sectionRef) => {
        window.scrollTo({
            top: sectionRef.current.offsetTop - 73,
            behavior: 'smooth',
        });
    };

    const handleNavClick = (sectionRef, url) => {
        if (location.pathname !== '/education/home') {
            navigate(url);
        } else {
            scrollToSection(sectionRef);
        }
    };

    return (
        <NavbarContainer scrollPosition={scrollPosition}>
            <LinksContainer>
                <NavLinkStyled to='/education/home'>Inicio</NavLinkStyled>
                <NavLinkStyled to='/education/altratec'
                    onClick={(e) => {
                        e.preventDefault();
                        navigate('/education/altratec');
                        window.scrollTo(0, 0); // Ensure the page scrolls to the top
                    }}>Altratec</NavLinkStyled>
                <NavLinkStyled to='/education/#specialized-training'
                    onClick={(e) => {
                        e.preventDefault();
                        handleNavClick(specializedTraining, '/education/#specialized-training');
                    }}>Capacitación especializada</NavLinkStyled>
                <NavLinkStyled to='/education/#collaborations'
                    onClick={(e) => {
                        e.preventDefault();
                        handleNavClick(collaborations, '/education/#collaborations');
                    }}>Colaboraciones</NavLinkStyled>
                <NavLinkStyled to='/education/#events'
                    onClick={(e) => {
                        e.preventDefault();
                        handleNavClick(events, '/education/#events');
                    }}>Eventos</NavLinkStyled>
                <NavLinkStyled to='/education/#contact'
                    onClick={(e) => {
                        e.preventDefault();
                        handleNavClick(educationContact, '/education/#contact');
                    }}>Contacto</NavLinkStyled>
            </LinksContainer>
        </NavbarContainer>
    );
};

export default EducationNavbar;
