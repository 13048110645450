// theme.js

const theme = {
  color: {
    primary: '#144773',
    secondary: '#2ecc71',
    background: '#ecf0f1',
    lightGray: '#E7E6EC',
    hoverLight: '#DADAE0',
    textPrimary: '#444444',
    textSecondary: '#95a5a6',
    babyBlue: '#96CAF2',
    accent: '#F3C055',
    light: '#FFFFF0',
    red: '#A4010E',
    // Add more colors as needed
  },
  fontFamily: {
    primary: 'Manuale',
    secondary: 'League Spartan',
    tertiary: 'Inter',
    // Add more fonts as needed
  },
  spacing: {
    small: '8px',
    medium: '16px',
    large: '24px',
    // Add more spacing values as needed
  },
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '16px',
    // Add more border radius values as needed
  },
  // Add any other theme values you need
};

export default theme;
