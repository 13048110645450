import Event1A from '../assets/events/09-10  11 2024/photo_5076069195871005982_y.jpg';
import Event1B from '../assets/events/09-10  11 2024/photo_5077820885397777396_y.jpg';
import Event1C from '../assets/events/09-10  11 2024/photo_5076069195871005982_y.jpg';
import Event1D from '../assets/events/09-10  11 2024/photo_5077820885397777396_y.jpg';
import Event2A from '../assets/events/12 03 2024/DSC_7650.JPG';
import Event2B from '../assets/events/12 03 2024/DSC_7764.JPG';
import Event2C from '../assets/events/12 03 2024/DSC_7777.JPG';
import Event2D from '../assets/events/12 03 2024/DSC_7781.JPG';
import Event2E from '../assets/events/12 03 2024/DSC_7810.JPG';
import Event2F from '../assets/events/12 03 2024/DSC_8060.JPG';
import Event2G from '../assets/events/12 03 2024/DSC_8334.JPG';
import Event3A from '../assets/events/12-13 06 2024/4.0.jpeg';
import Event3B from '../assets/events/12-13 06 2024/4.1.jpeg';
import Event3C from '../assets/events/12-13 06 2024/4.2.jpeg';
import Event3D from '../assets/events/12-13 06 2024/4.3.jpeg';
import Event3E from '../assets/events/12-13 06 2024/4.4.jpeg';
import Event3F from '../assets/events/12-13 06 2024/4.5.jpeg';
import Event3G from '../assets/events/12-13 06 2024/4.6.jpeg';
import Event3H from '../assets/events/12-13 06 2024/4.7.jpeg';
import Event3I from '../assets/events/12-13 06 2024/photo_5143309769338105153_y.jpg';
import Event3J from '../assets/events/12-13 06 2024/photo_5143309769338105154_y.jpg';
import Event4A from '../assets/events/14 06 2024/12.2.jpeg';
import Event4B from '../assets/events/14 06 2024/12.3.jpeg';
import Event4C from '../assets/events/14 06 2024/12.4.jpeg';
import Event4D from '../assets/events/14 06 2024/12.5.jpeg';
import Event4E from '../assets/events/14 06 2024/12.6.jpeg';
import Event4F from '../assets/events/14 06 2024/12.7.jpeg';
import Event4G from '../assets/events/14 06 2024/12.8.jpeg';
import Event4H from '../assets/events/14 06 2024/12.9.jpeg';
import Event4I from '../assets/events/14 06 2024/12.10.jpeg';
import Event5A from '../assets/events/16 07 2024/WhatsApp Image 2024-07-17 at 6.49.17 AM.jpeg';
import Event5B from '../assets/events/16 07 2024/WhatsApp Image 2024-07-17 at 6.49.23 AM.jpeg';
import Event5C from '../assets/events/16 07 2024/WhatsApp Image 2024-07-17 at 6.49.30 AM.jpeg';
import Event6A from '../assets/events/24 06 2024/2.0.jpeg';
import Event6B from '../assets/events/24 06 2024/photo_4908931844922256467_y.jpg';
import Event6C from '../assets/events/24 06 2024/photo_5177208284053089982_y.jpg';
import Event7A from '../assets/events/25 01 2024/Dr Manuel Quevedo Lopez.jpg';
import Event7B from '../assets/events/25 01 2024/DSC_0003.JPG';
import Event7C from '../assets/events/25 01 2024/DSC_0017.JPG';
import Event7D from '../assets/events/25 01 2024/DSC_0054.JPG';
import Event7E from '../assets/events/25 01 2024/DSC_0090.JPG';
import Event7F from '../assets/events/25 01 2024/DSC_1191.JPG';
import Event7G from '../assets/events/25 01 2024/DSC_1217.JPG';
import Event8A from '../assets/events/26 06 2024/photo_4906680045108571338_y.jpg';
import Event9A from '../assets/events/29 05 2024/10.0.jpeg';
import Event9B from '../assets/events/29 05 2024/10.1.jpeg';
import Event9C from '../assets/events/29 05 2024/10.2.jpeg';
import Event9D from '../assets/events/29 05 2024/10.3.jpeg';
import Event9E from '../assets/events/29 05 2024/WhatsApp Image 2024-05-29 at 6.50.53 PM.jpeg';
import Event9F from '../assets/events/29 05 2024/WhatsApp Image 2024-05-29 at 6.51.01 PM.jpeg';
import Event9G from '../assets/events/29 05 2024/WhatsApp Image 2024-05-29 at 6.51.14 PM.jpeg';
import Event9H from '../assets/events/29 05 2024/photo_5098306685398789145_y.jpg';
import Event9I from '../assets/events/29 05 2024/photo_5098306685398789146_y.jpg';
import Event9J from '../assets/events/29 05 2024/photo_5098306685398789225_y.jpg';
import Event10A from '../assets/events/Deloitte/photo_2023-09-26_21-52-44.jpg';
import Event10B from '../assets/events/Deloitte/photo_2023-09-30_22-47-50.jpg';
import Event11A from '../assets/events/Marzo 2023/359361885_781714443934224_7604150587507166242_n.jpg';
import Event11B from '../assets/events/Marzo 2023/359765571_674883044679680_6970610491023707998_n.jpg';
import Event11C from '../assets/events/Marzo 2023/F0ySrDjWAAE9CbN.jfif';
import Event11D from '../assets/events/Marzo 2023/photo_2023-07-11_15-47-40.jpg';
import Event12A from '../assets/events/5/photo_5021709014576247225_y.jpg';
import Event13A from '../assets/events/7/photo_5033259294031719585_y.jpg';
import Event13B from '../assets/events/7/photo_5037292427236453863_y.jpg';
import Event13C from '../assets/events/7/photo_5037292427236453864_y.jpg';
import Event14A from '../assets/events/8/photo_5179235079120071628_y.jpg';
import Event14B from '../assets/events/8/photo_5181789528099237103_y.jpg';
import Event15A from '../assets/events/9/EdoMex-2.jpg';
import Event15B from '../assets/events/9/photo_4954318271872609187_y.jpg';

const EventsData = [
  {
    name: 'Event 1',
    date: '2024/10/09T12:00:00:000Z',
    images: [Event1A, Event1B, Event1C, Event1D],
  },
  {
    name: 'Event 2',
    date: '2024/12/03T12:00:00:000Z',
    images: [Event2A, Event2B, Event2C, Event2D, Event2E, Event2F, Event2G],
  },
  {
    name: 'Event 3',
    date: '2024/06/12T12:00:00:000Z',
    images: [
      Event3A,
      Event3B,
      Event3C,
      Event3D,
      Event3E,
      Event3F,
      Event3G,
      Event3H,
      Event3I,
      Event3J,
    ],
  },
  {
    name: 'Event 4',
    date: '2024/06/14T12:00:00:000Z',
    images: [
      Event4A,
      Event4B,
      Event4C,
      Event4D,
      Event4E,
      Event4F,
      Event4G,
      Event4H,
      Event4I,
    ],
  },
  {
    name: 'Event 5',
    date: '2024/06/16T12:00:00:000Z',
    images: [Event5A, Event5B, Event5C],
  },
  {
    name: 'Event 6',
    date: '2024/06/24T12:00:00:000Z',
    images: [Event6A, Event6B, Event6C],
  },
  {
    name: 'Event 7',
    date: '2024/01/25T12:00:00:000Z',
    images: [Event7A, Event7B, Event7C, Event7D, Event7E, Event7F, Event7G],
  },
  {
    name: 'Event 8',
    date: '2024/06/26T12:00:00:000Z',
    images: [Event8A],
  },
  {
    name: 'Event 9',
    date: '2024/05/29T12:00:00:000Z',
    images: [
      Event9A,
      Event9B,
      Event9C,
      Event9D,
      Event9E,
      Event9F,
      Event9G,
      Event9H,
      Event9I,
      Event9J,
    ],
  },
  {
    name: 'Event 10',
    date: '2023/09/26T12:00:00:000Z',
    images: [Event10A, Event10B],
  },
  {
    name: 'Event 11',
    date: '2023/03/01T12:00:00:000Z',
    images: [Event11A, Event11B, Event11C, Event11D],
  },
  {
    name: 'Event 12',
    date: '2023/01/01T12:00:00:000Z',
    images: [Event12A],
  },
  {
    name: 'Event 13',
    date: '2023/01/01T12:00:00:000Z',
    images: [Event13A, Event13B, Event13C],
  },
  {
    name: 'Event 14',
    date: '2023/01/01T12:00:00:000Z',
    images: [Event14A, Event14B],
  },
  {
    name: 'Event 15',
    date: '2023/01/01T12:00:00:000Z',
    images: [Event15A, Event15B],
  },
];

export default EventsData;
