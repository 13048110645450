import React from 'react';
import { styled } from '@mui/material';
import theme from '../../styles';

import Navbar from '../../components/Navbar';
import ContactSection from '../../sections/ContactSection';

const Contact = () => {
  return (
    <MainContainer>
      <Navbar color={theme.color.primary}></Navbar>
      <ContactSection></ContactSection>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({}));

export default Contact;
