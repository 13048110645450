import { styled } from '@mui/material';
import theme from '../../styles';

const AltratecFormationProgram = (props) => {
  return (
    <MainContainer>
      <H1>¿Cómo es el proceso de formación en el Programa Dual Alemán?</H1>
      <Paragraph>
        El profesionista se desarrolla en procesos de instrucción y supervisión,
        adquiriendo  bases sólidas en cursos intensivos y competencias laborales
        al mismo tiempo, con aprendizajes constructivistas impartidos de un
        “MEISTER”, o formado especializado, y con atención individual por medio
        de expertos en el campo. Las herramientas para el desarrollo de los
        materiales las provee Altratec. Se evita la memorización y la aprobación
        por repetición en el proceso de aprendizaje. Los contenidos se
        establecen entre los requisitos del sistema educativo y las exigencias
        adicionales de los empresarios.
      </Paragraph>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '50px 100px',
  gap: 20,
}));
const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));
const Paragraph = styled('p')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: theme.color.textPrimary,
  textAlign: 'left',
}));

export default AltratecFormationProgram;
