import MainNavbar from '../../../../components/MainNavbar';
import AltratecNavbar from '../../../../components/AltratecNavbar';
import AltratecHeader from '../../../../sections/AltratecHeader';
import ContactSection from '../../../../sections/ContactSection';
import TitleAndParagraphs from '../../../../sections/TitleAndParagraphs';

function AltratecAdmissions() {
  return (
    <>
      <MainNavbar />
      <AltratecHeader />
      <AltratecNavbar />
      <TitleAndParagraphs
        title={'¿Quieres registrar tu adminsión?'}
        paragraphs={['Llena este formulario']}
      />
      <ContactSection />
    </>
  );
}

export default AltratecAdmissions;
