import { styled } from '@mui/material';
import theme from '../../styles';

const AltratecOperations = (props) => {
  return (
    <MainContainer>
      <H1>Modelo de operación</H1>
      <Paragraph>
        En el modelo dual alemán se seleccionan los candidatos por la misma
        empresa. El personal se desarrolla con base al requerimiento futuro. No
        se capacita en mayor número a la demanda.
        <br />
        <br />
        Los aprendices reciben formación para el perfil completo que exige la
        profesión. Adicionalmente, rotan durante su periodo de entrenamiento en
        los departamentos probables de absorción. El enfoque gira alrededor de
        perfiles y puestos dentro de empresas de cualquier tamaño.
        <br />
        <br />
        El programa permite obtener estándares elevados de calidad a costo
        reducido con respecto a la formación interna, en particular con grandes
        ventajas medibles contra la formación escolarizada tradicional.
      </Paragraph>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '50px 100px',
  gap: 20,
}));
const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));
const Paragraph = styled('p')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: theme.color.textPrimary,
  textAlign: 'left',
}));

export default AltratecOperations;
